<template>
  <div class="ApplicationLibraryPlatformList-wrapper">
    <list exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="批量设置公司应用"
                  @click="batchGrantApplication" />
        <v-button text="设置公司授权应用"
                  @click="grantApplication" />
        <v-button text="新增"
                  permission="add"
                  @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="应用名称"
                 v-model="searchParams.subName"
                 :maxlength="10" />
        <v-select label="所属类别"
                  v-model="searchParams.subHandle"
                  :options="subHandleOps" />
        <v-select label="应用类型"
                  v-model="searchParams.subType"
                  :options="subTypeOps" />
        <v-select label="应用状态"
                  v-model="searchParams.subStatus"
                  :options="statusOps" />
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      :maxDate="new Date()"
                      :startTime.sync="searchParams.startIntime"
                      :endTime.sync="searchParams.endIntime" />
        <v-input label="应用编码"
                 v-model="searchParams.subCode"
                 :maxlength="5" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="edit"
                  @click="edit(scope.row)" />
        <v-button text="二维码"
                  type="text"
                  permission="qrcode"
                  @click="showQrcode(scope.row)" />
      </template>
    </list>
    <dialog-qrcode ref="dialogQrcode"></dialog-qrcode>
  </div>
</template>

<script>
import { createImgVNode } from 'common/vdom'
import { getListURL, exportListURL } from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap, setStatusOps } from '../map'
import DialogQrcode from '../DialogQrcode'

export default {
  name: 'ApplicationLibraryPlatformList',
  components: {
    DialogQrcode
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      statusOps: setStatusOps(1),
      searchParams: {
        subName: '',
        subHandle: undefined,
        subType: undefined,
        subStatus: undefined,
        startIntime: '',
        endIntime: '',
        subCode: ''
      },
      headers: [
        {
          prop: 'subImg',
          label: '应用图标',
          formatter: (row, prop) => createImgVNode(this, row, prop)
        },
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'subCode',
          label: '应用编码'
        },
        {
          prop: 'subHandleText',
          label: '所属类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    grantApplication () {
      this.$router.push({
        name: 'grantApplicationPlatformForm'
      })
    },
    batchGrantApplication () {
      this.$router.push({
        name: 'bacthGrantApplicationPlatformForm'
      })
    },
    create () {
      this.$router.push({
        name: 'applicationLibraryPlatformForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'applicationLibraryPlatformForm',
        query: {
          subId: row.id
        }
      })
    },
    showQrcode (row) {
      this.$refs.dialogQrcode.showQrcode(row)
    }
  }
}
</script>
